
import {Component, Vue, Watch} from "vue-property-decorator";
import SolidLayout from "../components/SolidLayout.vue";
import InlineDropdown from "@/components/InlineDropdown.vue";
import {QuestionAction} from "@/models/QuestionAction";
import {QuestionLibrary} from "@/models/QuestionLibrary";
import {Question} from "@/models/Question";
import BackNavigation from "@/components/BackNavigation.vue";

type QuestionTuple = [string, QuestionAction]

interface UrlParams {
  n: string;
  a: QuestionTuple[];
}

interface QuestionStatus {
  question: Question;
  status: QuestionAction;
}

@Component({
  components: {BackNavigation, InlineDropdown, SolidLayout}
})
export default class Decision extends Vue {
  public readonly questionAction = QuestionAction

  private questionProgress: QuestionTuple[] = []

  private questionLibrary = new QuestionLibrary()

  private newMeetingName = ""

  private meetingName = ""

  public get meetingNameWrapper() {
    return this.meetingName ? `meeting "${this.meetingName}"` : "upcoming meeting"
  }

  public get pageUrl() {
    return window.location.href
  }

  public get latestQuestion(): QuestionStatus {
    const questionProgressItem = this.questionProgress[this.questionProgress.length - 1]
    return {
      question: this.questionLibrary.getQuestion(questionProgressItem[0]),
      status: questionProgressItem[1]
    }
  }

  @Watch('meetingName')
  meetingNameChange() {
    this.setUrlParams()
  }

  @Watch('questionProgress')
  questionProgressChange() {
    this.setUrlParams()
  }

  mounted() {
    const params = this.$route.params.settings

    if (params) this.readUrlParams(params.toString())
  }

  public readUrlParams(params: string) {
    const dataObject: UrlParams = JSON.parse(decodeURIComponent(escape(atob(decodeURIComponent(params)))))

    this.meetingName = dataObject.n
    this.questionProgress = dataObject.a
  }

  public setUrlParams() {
    const dataObject: UrlParams = {
      n: this.meetingName,
      a: this.questionProgress
    }

    const dataLine = encodeURIComponent(btoa(unescape(encodeURIComponent(JSON.stringify(dataObject)))))
    if (this.$route.params.settings && this.$route.params.settings === dataLine) {
      // it's the same route, no need to update
      return
    }

    const newUrl = '/decision/' + dataLine
    this.$router.push(newUrl)
  }

  public nameEdit() {
    this.meetingName = this.newMeetingName
    this.newMeetingName = '';
    (this.$refs.nameEdit as any).toggleDropdown()
  }

  public startProcess() {
    const questionList = this.questionLibrary.listQuestionIds()
    this.questionProgress.push([questionList[0], QuestionAction.NONE])
  }

  public stepBack() {
    this.questionProgress.pop()
    this.setQuestionProgressStatus(QuestionAction.NONE)
  }

  private setQuestionProgressStatus(status: QuestionAction) {
    if (this.questionProgress.length === 0) return
    const lastQuestionStatus = this.questionProgress[this.questionProgress.length - 1]
    lastQuestionStatus[1] = status
    this.questionProgress.splice(this.questionProgress.length - 1, 1, lastQuestionStatus)
  }

  public actionClick(action: QuestionAction) {
    const question = this.latestQuestion.question
    const questionNext = question.nextAction

    if (action === QuestionAction.NEXT && !questionNext) {
      // it's a meeting!
      this.setQuestionProgressStatus(QuestionAction.MEETING)
      return
    }

    if (action === QuestionAction.NEXT && questionNext) {
      this.setQuestionProgressStatus(action)
      this.questionProgress.push([questionNext, QuestionAction.NONE])
      return;
    }

    if (action === QuestionAction.PREVIOUS) {
      this.stepBack()
      return;
    }

    if (action === QuestionAction.MEETING) {
      this.setQuestionProgressStatus(action)
      return;
    }

    if (action === QuestionAction.NO_MEETING) {
      this.setQuestionProgressStatus(action)
      return;
    }
  }

  public noMeetingSelected(action: QuestionAction) {
    return this.latestQuestion.status === QuestionAction.NO_MEETING && action === QuestionAction.NO_MEETING
  }

}
