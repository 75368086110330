
import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class InlineDropdown extends Vue {
  @Prop({default: "click to change"})
  private title!: string

  @Prop({required: true})
  private caption!: string

  private showEdit = false

  private rand = Math.floor(Math.random()*1000)

  public toggleDropdown() {
    this.showEdit = !this.showEdit
  }

  public hideDropdown() {
    this.showEdit = false
  }
}
